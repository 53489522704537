import { v4 as uuidv4 } from 'uuid';
import { defineStore } from 'pinia';
import { roundTo } from 'round-to';
import { type LooseObject, type Nullable } from '@/types/generic';
import type { Plan, SetUp, Term } from '@/types/products';
import { useAuthStore } from '@/stores/auth';
import { handleError } from '~/utils/errors';
import { formatDate } from '~/utils/date';
import productList from '~/lib/products';
import { getFunnelBenefitLabel } from '~/utils/funnels';

export interface SignupAddress {
  id: number;
  full_address: string;
  fibre?: boolean;
  hyper?: boolean;
  lfc: string;
  plans: Record<string, { key: string; core_product_key: string }[]>;
}

export interface OrderCustomerAddress {
  address: string;
  address_id: number;
  billing_address: string;
  billing_address_id?: number;
  delivery_address: string;
  delivery_address_id?: number;
  order_token: string;
  metadata?: OrderCustomerMetadata;
  connection?: OrderCustomerConnection;
}

export interface OrderCustomerConnection extends LooseObject {}

export interface OrderCustomerMetadata extends LooseObject {}

export interface SignupDetails extends LooseObject {
  address?: SignupAddress;
  plan?: Plan;
  // supportPlan?: SupportPlan;
  // modem?: Modem;
  term?: Term;
  includeRouter: boolean;
  fromLanding?: boolean;
  promo?: string;
  referrer?: string;
  referralCode?: string;
}

export interface OrderTotals {
  oneOff: number;
  monthly: number;
  total: number;
}

export interface SignupIsp {
  broadbandTransfer: boolean;
  broadbandNew: boolean;
  canContinue: boolean;
  transferFrom: string;
  nameOnAccount: string;
  accountNumber: string;
  connectionDate: Nullable<Date>;
  landlineTransfer: boolean;
  landlineTransferPhoneNumber: string;
  landlineNew: boolean;
  landlineNewLocalArea: string;
  landlineNewPhoneNumber: string;
  landlinePlan: string;
}

export interface SignupPurchaseVars {
  uid: string;
  oneOffItems: string[];
  monthlyTotal: number;
  monthlyTotalTax: number;
  oneOffTotal: number;
}

export interface State {
  steps: number;
  isLoading: boolean;
  currentStep: number;
  signupDetails: SignupDetails;
  signupIsp: SignupIsp;
  orderToken: string;
  offerHalfPrice: number;
  noTermDiscount: boolean;
  paymentError: Nullable<string>;
}

const getSignupDetailsDefaults = (): SignupDetails => ({
  plan: undefined,
  // supportPlan: undefined,
  // modem: undefined,
  term: productList().terms[0],
  includeRouter: true,
  fromLanding: undefined,
  referrer: undefined,
});

const getSignupIspDefaults = (): SignupIsp => ({
  broadbandTransfer: true,
  broadbandNew: false,
  canContinue: false,
  transferFrom: '',
  nameOnAccount: '',
  accountNumber: '',
  connectionDate: null,
  // new fields
  landlineTransfer: false,
  landlineTransferPhoneNumber: '',
  landlineNew: false,
  landlineNewLocalArea: '',
  landlineNewPhoneNumber: '',
  landlinePlan: 'local',
});

const DEFAULT_OFFER_HALF_PRICE = 5;

export const BBC_PROMO_CODE = 'bbc';

export const useMemberSignup = defineStore('signup', {
  persist: true,
  state: (): State => ({
    signupDetails: getSignupDetailsDefaults(),
    currentStep: 1,
    steps: 6,
    isLoading: false,
    signupIsp: getSignupIspDefaults(),
    orderToken: '',
    offerHalfPrice: DEFAULT_OFFER_HALF_PRICE,
    noTermDiscount: false,
    paymentError: null,
  }),
  getters: {
    signupNoTerm(state) {
      return state.signupDetails.term?.noTermPrice === true;
    },
    signupRequiresBond(state) {
      return state.signupDetails.term?.noTermPrice === true && state.signupDetails.includeRouter && !state.signupDetails.plan?.hyper;
    },
    signupIncludeRouter(state) {
      return state.signupDetails.includeRouter && !state.signupDetails.plan?.hyper;
    },
    signupIsHyper(state) {
      return state.signupDetails.plan?.hyper === true;
    },
    hasNextStep(state) {
      return state.currentStep < state.steps;
    },
    hasPrevStep(state) {
      return state.currentStep > 1;
    },
    offerHalfPriceLabel(state) {
      switch (state.offerHalfPrice) {
        case 2:
          return 'two';
        case 3:
          return 'three';
        case 4:
          return 'four';
        case 5:
          return 'five';
        case 6:
          return 'six';
      }
    },
    signupIspSetupValid(state) {
      return state.signupIsp.canContinue;
    },

    signupOrderSetup(): SetUp {
      const { setup } = productList();
      return setup; // this.hasBbcPromo ? setupPromoBbc : setup;
    },

    orderPriceIds(state) {
      const priceIds: string[] = [];

      if (state.signupDetails.plan) {
        // no term price ID
        if (this.signupNoTerm && state.signupDetails.plan.stripeNoTermPriceCode) {
          priceIds.push(state.signupDetails.plan.stripeNoTermPriceCode);
        } else if (this.hasBbcPromo && state.signupDetails.plan.promo?.bbc?.stripePriceCode) {
          priceIds.push(state.signupDetails.plan.promo.bbc.stripePriceCode);
        } else if (state.signupDetails.plan.stripePriceCode) {
          priceIds.push(state.signupDetails.plan.stripePriceCode);
        }
      }

      const { bond, delivery } = productList();
      if (this.signupRequiresBond) {
        priceIds.push(bond.stripePriceCode);
      }
      if (this.signupIncludeRouter) {
        priceIds.push(delivery.stripePriceCode);
      }

      priceIds.push(this.signupOrderSetup.stripePriceCode);

      return priceIds.length ? priceIds : undefined;
    },

    purchaseVars(state): SignupPurchaseVars {
      const vars: SignupPurchaseVars = {
        uid: uuidv4(),
        oneOffItems: [],
        monthlyTotal: 0.0,
        monthlyTotalTax: 0.0,
        oneOffTotal: 0.0,
      };

      if (state.signupDetails.plan) {
        const price = this.signupNoTerm ? state.signupDetails.plan.noTermPrice : state.signupDetails.plan.price;
        if (price) {
          vars.monthlyTotal += price / 100;
        }
      }

      const { bond, delivery } = productList();
      if (this.signupRequiresBond) {
        vars.oneOffItems.push(bond.name);
        vars.oneOffTotal += bond.price / 100;
      }

      if (this.signupIncludeRouter) {
        vars.oneOffItems.push(delivery.name);
        vars.oneOffTotal += delivery.price / 100;
      }

      vars.oneOffItems.push(this.signupOrderSetup.name);
      vars.oneOffTotal += this.signupOrderSetup.price / 100;

      vars.monthlyTotalTax = roundTo(vars.monthlyTotal * 0.15, 2); // GST
      vars.oneOffTotal = roundTo(vars.oneOffTotal, 2);

      return vars;
    },

    purchaseEventVars(state): LooseObject {
      let planName = state.signupDetails.plan?.name || '';
      if (this.signupNoTerm) {
        planName += ' - No Term';
      }

      return {
        value: this.purchaseVars.monthlyTotal,
        tax: this.purchaseVars.monthlyTotalTax,
        one_off_items: this.purchaseVars.oneOffItems.join(','),
        one_off_total: this.purchaseVars.oneOffTotal,
        currency: 'NZD',
        coupon: '',
        items: [
          {
            item_id: state.signupDetails.plan?.id,
            item_name: planName,
            coupon: '',
            price: this.purchaseVars.monthlyTotal,
            item_category: 'Plans',
            quantity: 1,
          },
        ],
      };
    },

    orderSignupTerm(state): string {
      let benefit = '';
      if (state.signupDetails.term) {
        if (state.noTermDiscount && state.signupDetails.term.discountBenefit) {
          benefit = state.signupDetails.term.discountBenefit;
        } else if (this.hasBbcPromo) {
          benefit = '6 months half price';
        } else if (state.signupDetails.term.benefit) {
          benefit = getFunnelBenefitLabel(state.signupDetails.term.benefit, state.offerHalfPrice);
        }
      }
      return benefit;
    },

    hasBbcPromo(state): boolean {
      return state.signupDetails.promo === BBC_PROMO_CODE && state.signupDetails.term?.id === 1;
    },

    hasBbcPromoPrice(state): boolean {
      return !!(this.hasBbcPromo && state.signupDetails.plan?.promo?.bbc?.price);
    },

    orderContract(state): string {
      let contract = '';
      if (state.signupDetails.term) {
        if (state.noTermDiscount && state.signupDetails.term.contractDiscount) {
          contract = state.signupDetails.term?.contractDiscount;
        } else {
          contract = state.signupDetails.term?.contract;
        }
      }
      return contract;
    },

    orderConnection(state): OrderCustomerConnection {
      const connection: OrderCustomerConnection = {
        broadband: {
          transfer: state.signupIsp.broadbandTransfer,
          from_isp: state.signupIsp.transferFrom,
          name_on_account: state.signupIsp.nameOnAccount,
          account_number: state.signupIsp.accountNumber,
        },
        landline: {},
      };

      if (state.signupIsp.landlineTransfer) {
        connection.landline.type = 'transfer';
        connection.landline.phone = state.signupIsp.landlineTransferPhoneNumber;
        connection.landline.plan = state.signupIsp.landlinePlan;
      } else if (state.signupIsp.landlineNew) {
        connection.landline.type = 'new';
        connection.landline.phone = state.signupIsp.landlineNewPhoneNumber;
        connection.landline.plan = state.signupIsp.landlinePlan;
      }

      return connection;
    },

    orderPlanName(state): string {
      if (state.signupDetails.plan?.affinityName) {
        return state.signupDetails.plan.affinityName;
      }
      if (state.signupDetails.plan?.name) {
        return state.signupDetails.plan.name;
      }

      return '-';
    },

    orderMetadata(state): OrderCustomerMetadata {
      return {
        desired_connection_date: state.signupIsp.connectionDate ? formatDate(state.signupIsp.connectionDate) : '-',
        signup_term: this.orderSignupTerm,
        support_plan: '-',
        include_router: this.signupIncludeRouter ? 'yes' : 'no',
        referral_code: state.signupDetails.referralCode || '',
        promo: this.hasBbcPromo ? state.signupDetails.promo || '' : '',
      };
    },

    orderAddress(state): OrderCustomerAddress | undefined {
      if (!state.signupDetails.address) {
        return undefined;
      }

      return {
        address: state.signupDetails.address.full_address,
        address_id: state.signupDetails.address.id,
        billing_address: state.signupDetails.address.full_address,
        billing_address_id: state.signupDetails.address.id,
        delivery_address: state.signupDetails.address.full_address,
        delivery_address_id: state.signupDetails.address.id,
        plan_key: state.signupDetails.plan?.planKey,
        plan: this.orderPlanName,
        order_token: this.orderToken,
        contract: this.orderContract,
        connection: this.orderConnection,
        metadata: this.orderMetadata,
      } as OrderCustomerAddress;
    },

    orderTotal(state) {
      const totals: OrderTotals = {
        oneOff: 0,
        monthly: 0,
        total: 0,
      };

      if (state.signupDetails.plan) {
        const price = this.signupNoTerm ? state.signupDetails.plan.noTermPrice : state.signupDetails.plan.price;
        if (price) {
          totals.monthly += price;
          totals.total += price;
        }
      }

      const { bond, delivery, discounts } = productList();

      if (this.signupRequiresBond) {
        totals.oneOff += bond.price;
      }

      if (this.signupIncludeRouter) {
        totals.oneOff += delivery.price;
      }

      totals.oneOff += this.signupOrderSetup.price;

      if (state.signupDetails.referralCode) {
        totals.oneOff += discounts.referralDiscount.price;
        if (totals.oneOff < 0) {
          totals.oneOff = 0;
        }
      }

      return totals;
    },
  },
  actions: {
    setStep(step: number) {
      this.currentStep = step;
    },
    nextStep() {
      if (this.currentStep < this.steps) {
        this.currentStep = this.currentStep + 1;
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep = this.currentStep - 1;
      }
    },
    saveDetails(details: LooseObject) {
      this.signupDetails = Object.assign({}, this.signupDetails, details);
    },
    reset() {
      this.signupDetails = getSignupDetailsDefaults();
      this.signupIsp = getSignupIspDefaults();
      this.currentStep = 1;
      this.orderToken = '';
    },

    setOfferHalfPrice(price: null | number = null) {
      this.offerHalfPrice = price || DEFAULT_OFFER_HALF_PRICE;
    },

    setNoTermDiscount(noTermDiscount: boolean = false) {
      this.noTermDiscount = noTermDiscount;
    },

    async confirmPayment() {
      try {
        this.paymentError = null;

        const accessToken = await this.$auth0.getAccessTokenSilently();

        const response = await this.$api.post(
          '/customer/payment/confirm',
          {
            address: this.orderAddress,
            price_ids: this.orderPriceIds,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (response.status === 200 || response.status === 201) {
          return true;
        }
      } catch (err: any) {
        this.paymentError = err?.data?.message || 'Oops! There’s been a problem with your card. Please check the details and try again.';
        handleError(err);
      }
      return false;
    },

    validateStep(step: number): boolean {
      const auth = useAuthStore();
      const hasAddress = !!this.signupDetails.address;
      const hasPlan = !!this.signupDetails.plan;
      const hasTerm = !!this.signupDetails.term;

      switch (step) {
        case 2:
          return hasAddress;
        case 3:
          return hasAddress && hasPlan;
        case 4:
          return hasAddress && hasPlan;
        case 5:
          return hasAddress && hasPlan && auth.customerExists;
        case 6:
          return hasAddress && hasPlan && auth.customerExists && this.signupIspSetupValid;
        case 7:
          return hasAddress && hasPlan && hasTerm && auth.customerExists && this.signupIspSetupValid;
      }

      return step === 1;
    },

    generateOrderToken(keepExisting = true) {
      if (keepExisting && this.orderToken) {
        return;
      }
      this.orderToken = uuidv4();
    },
  },
});
