import type { Plan, Term } from '~/types/products';
import { BBC_PROMO_CODE } from '~/stores/signup';

export function getFunnelBenefitLabel(label: string, duration: number) {
  return label.replace('[duration]', `${duration}`);
}

export const getTermDescription = (option: Term, promo: any = '', plan: Plan) => {
  if (option.signupPrice && !plan.signupPrice) {
    return '';
  }
  if (promo === BBC_PROMO_CODE && option.id === 1) {
    return plan.promo?.bbc?.price ? '6 months half price' : '';
  }

  return option.benefit;
};
